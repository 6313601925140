import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EXTERNAL_ROUTES } from '@libs/auth/enums/external-routes.enum';
import { AuthService } from '@libs/auth/services/auth.service';
import { AuthFacade } from '@libs/auth/store/facades/auth.facade';
import { PublicService } from '@libs/common/services/public/public.service';
import { SharedFacade } from '@libs/common/store/facades/shared.facade';
import { Staff } from '@libs/data/features/staff/models/staff.model';
import { Student } from '@libs/data/features/student/models/student.model';
import { IBreadcrumb } from '@libs/ui-kit/components/breadcrumbs/models/breadcrumbs.model';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public breadcrumbs: IBreadcrumb[] = [];
  public currentUser: Student | Staff;
  public topbarUrls: { chat: string; faq: string; career: string } = {
    chat: '',
    faq: '',
    career: '',
  };
  public selectedLanguage = '';
  public loading = true;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly authFacade: AuthFacade,
    private readonly authService: AuthService,
    private readonly sharedFacade: SharedFacade,
    private readonly publicService: PublicService
  ) {}

  ngOnInit(): void {
    this.authFacade.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.topbarUrls.chat = this.publicService.getChatUrl(this.currentUser.country);
        this.topbarUrls.faq = this.publicService.getFaqUrl(this.currentUser.language);
        this.topbarUrls.career = this.publicService.getCareerUrl(this.currentUser.country);
      }
    });

    this.sharedFacade.breadcrumbs$.pipe(takeUntil(this.destroy$)).subscribe((breadcrumbs) => {
      if (breadcrumbs) {
        this.breadcrumbs = breadcrumbs;
        this.loading = false;
      }
    });

    this.sharedFacade.language$
      .pipe(takeUntil(this.destroy$))
      .subscribe((lan) => (this.selectedLanguage = lan as string));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public handleRedirectToHome(): void {
    this.router.navigateByUrl('/dashboard/homepage');
  }

  public handleRedirectToSettings(): void {
    location.href = EXTERNAL_ROUTES.PROFILE;
  }

  public updateUserLanguage(language: string): void {
    this.sharedFacade.updateUserLanguage(language);
  }

  public logout(): void {
    this.authService.logout();
  }
}
