import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthFacade } from '@libs/auth/store/facades/auth.facade';
import { minutesToMilliseconds } from '@libs/common//utils/time';
import { CacheService } from '@libs/common/services/cache/cache.service';
import { SharedFacade } from '@libs/common/store/facades/shared.facade';
import { Staff } from '@libs/data/features/staff/models/staff.model';
import { Student } from '@libs/data/features/student/models/student.model';
import { Language, getLanguage } from '@libs/data/features/user/models/language.model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { Meta } from '@angular/platform-browser';
export interface IntercomData {
  uid: string;
  email: string;
  role: string;
  createdAt: string;
  classes: string;
  firstNames: string;
  lastNames: string;
  profilePhotoUrl: string;
  githubUrl: string;
}

declare global {
  interface Window {
    Intercom: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends CacheService implements OnInit, OnDestroy {
  title = 'Develhope | Corsi online per sviluppatore software';

  public selectedLanguage: Language;
  public isOnBoard!: boolean;
  public intercomData: IntercomData = {} as IntercomData;
  public loading$: Observable<boolean>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly gtmService: GoogleTagManagerService,
    private readonly router: Router,
    private readonly sharedFacade: SharedFacade,
    private readonly authFacade: AuthFacade,
    private readonly metaService: Meta
  ) {
    super();
    this.gtmService.addGtmToDom();

    this.metaService.addTags([{ name: 'robots', content: 'noindex, nofollow' }]);

    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        this.gtmService.pushTag(gtmTag);

        if (item.url !== item.urlAfterRedirects) {
          this.sharedFacade.clearBreadCrumbs();
        }
      }
    });

    this.sharedFacade.showSnackBar$.subscribe((showSnackBar) => {
      if (showSnackBar) {
        this.sharedFacade.clearSnackBar();
      }
    });
  }

  ngOnInit(): void {
    const uuid = uuidv4();

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.sharedFacade.setRedirectUrl(evt.url);

        if (this.shouldScrollToTop(this.router.url)) {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
      }
    });

    this.loading$ = this.sharedFacade.loading$;

    this.authFacade.user$
      .pipe(takeUntil(this.destroy$), filter(Boolean), distinctUntilChanged())
      .subscribe((user: Student | Staff) => {
        if (user?.id) {
          this.isOnBoard = true;
          this.sharedFacade.setLanguage(user.language as Language);
        }

        this.setDataLayer(user);

        this.sharedFacade.startUserSessionTimeTracking(minutesToMilliseconds(5));
      });

    this.selectedLanguage = getLanguage(this.isOnBoard);
    this.sharedFacade.setLanguage(this.selectedLanguage);

    this.intercomData = {
      uid: `logged-out-${uuid}`,
      firstNames: 'Utente',
      lastNames: 'Anonimo',
    } as IntercomData;

    // Intercom Init
    window.Intercom('boot', {
      app_id: 'v304ltno',
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private shouldScrollToTop(url: string): boolean {
    return Boolean(!(url.includes('orderBy') && url.includes('orderDirection')));
  }

  private setDataLayer(user: Staff | Student): void {
    const classes = user instanceof Student ? user.attendedClasses.join(',') : '';

    this.intercomData = {
      classes,
      uid: user.id,
      email: user.email,
      role: user.role,
      createdAt: user.createdAt,
      firstNames: user.firstname,
      lastNames: user.lastname,
      profilePhotoUrl: user.personalData.profilePhotoUrl,
      githubUrl: user.githubUrl,
    };

    window.Intercom('update', {
      user_id: this.intercomData.uid,
      name: `${this.intercomData.firstNames} ${this.intercomData.lastNames}`,
      email: this.intercomData.email,
      platform_githubUrl: this.intercomData.githubUrl,
      platform_classes: this.intercomData.classes,
      platform_role: this.intercomData.role,
      platform_createdAt: this.intercomData.createdAt,
      platform_profilePhotoUrl: this.intercomData.profilePhotoUrl,
    });
  }
}
